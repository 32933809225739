import {useMemo} from 'react';
import {FormikProps} from 'formik';
import {Col, Row, Table} from 'reactstrap';

import {FormikNumberInput, FormikPercentageInput} from '@reasoncorp/kyber-js';

import {form4027iUtils, formatDecimal, formatInteger, isNumber} from '../../../utils';
import {Form4027iClassificationKey, Form4027iRealPropertyClassificationDto} from '../../../types/forms/form4027i';

type Props = {
  setHasUnsavedChanges: (hasUnsavedChanges: boolean) => void
  showReadOnlyView: boolean
  formikProps: FormikProps<any>
  isAugustStatusEditable: boolean
  isJuneStatusEditable: boolean
  selectedTab: string
  formType: string
}

const Form4027iRealPropertyTab = ({
                                    setHasUnsavedChanges,
                                    showReadOnlyView,
                                    formikProps,
                                    selectedTab,
                                    isAugustStatusEditable,
                                    isJuneStatusEditable,
                                    formType
                                  }: Props) => {
  const {
    june30Status,
    august31Status,
    october31Status
  } = useMemo(() => {
    return formikProps.values
      .classifications[selectedTab as Form4027iClassificationKey] as Form4027iRealPropertyClassificationDto;
  }, [
    formikProps,
    selectedTab
  ]);

  const classificationCalculations = useMemo(() => form4027iUtils.getClassificationCalculations(
    formType,
    formikProps,
    selectedTab
  ), [
    formType,
    formikProps,
    selectedTab
  ]);

  const numberOfParcelsInStudy = useMemo(() => {
    return form4027iUtils.getNumberOfParcelsInStudy(formikProps, selectedTab);
  }, [
    formikProps,
    selectedTab
  ]);

  const june30NumberOfParcelsInStudy = useMemo(() => {
    const shouldUseCalculatedValue = formType === 'FORM_4027I_JUNE' || formType === 'FORM_4027I';
    return shouldUseCalculatedValue ? numberOfParcelsInStudy :
      june30Status?.numberOfParcelsInStudy ?? null;
  }, [
    formType,
    numberOfParcelsInStudy,
    june30Status
  ]);

  const june30StatusParcelSelectionPercentCompleted = useMemo(() => {
    if (numberOfParcelsInStudy !== 0 &&
      isNumber(classificationCalculations.june30Status.parcelSelectionPercentCompleted)) {
      return classificationCalculations.june30Status.parcelSelectionPercentCompleted;
    } else {
      return 0;
    }
  }, [
    numberOfParcelsInStudy,
    classificationCalculations
  ]);

  const june30StatusFieldWorkPercentCompleted = useMemo(() => {
    if (numberOfParcelsInStudy !== 0 &&
      isNumber(classificationCalculations.june30Status.fieldWorkPercentCompleted)) {
      return classificationCalculations.june30Status.fieldWorkPercentCompleted;
    } else {
      return 0;
    }
  }, [
    numberOfParcelsInStudy,
    classificationCalculations
  ]);

  const june30StatusAppraisalDataEntryPercentCompleted = useMemo(() => {
    if (numberOfParcelsInStudy !== 0 &&
      isNumber(classificationCalculations.june30Status.appraisalDataEntryPercentCompleted)) {
      return classificationCalculations.june30Status.appraisalDataEntryPercentCompleted;
    } else {
      return 0;
    }
  }, [
    numberOfParcelsInStudy,
    classificationCalculations
  ]);

  const august31NumberOfParcelsInStudy = useMemo(() => {
    return formType !== 'FORM_4027I_OCT' ? numberOfParcelsInStudy :
      august31Status?.numberOfParcelsInStudy ?? null;
  }, [
    formType,
    numberOfParcelsInStudy,
    august31Status
  ]);

  const august31StatusParcelSelectionPercentCompleted = useMemo(() => {
    if (numberOfParcelsInStudy !== 0 &&
      isNumber(classificationCalculations.august31Status.parcelSelectionPercentCompleted)) {
      return classificationCalculations.august31Status.parcelSelectionPercentCompleted;
    } else {
      return 0;
    }
  }, [
    numberOfParcelsInStudy,
    classificationCalculations
  ]);

  const august31StatusFieldWorkPercentCompleted = useMemo(() => {
    if (numberOfParcelsInStudy !== 0 &&
      isNumber(classificationCalculations.august31Status.fieldWorkPercentCompleted)) {
      return classificationCalculations.august31Status.fieldWorkPercentCompleted;
    } else {
      return 0;
    }
  }, [
    numberOfParcelsInStudy,
    classificationCalculations
  ]);

  const august31StatusAppraisalDataEntryPercentCompleted = useMemo(() => {
    if (numberOfParcelsInStudy !== 0 &&
      isNumber(classificationCalculations.august31Status.appraisalDataEntryPercentCompleted)) {
      return classificationCalculations.august31Status.appraisalDataEntryPercentCompleted;
    } else {
      return 0;
    }
  }, [
    numberOfParcelsInStudy,
    classificationCalculations
  ]);

  const october31StatusParcelSelectionPercentCompleted = useMemo(() => {
    if (numberOfParcelsInStudy !== 0 &&
      isNumber(classificationCalculations.october31Status.parcelSelectionPercentCompleted)) {
      return classificationCalculations.october31Status.parcelSelectionPercentCompleted;
    } else {
      return 0;
    }
  }, [
    numberOfParcelsInStudy,
    classificationCalculations
  ]);

  const october31StatusFieldWorkPercentCompleted = useMemo(() => {
    if (numberOfParcelsInStudy !== 0 &&
      isNumber(classificationCalculations.october31Status.fieldWorkPercentCompleted)) {
      return classificationCalculations.october31Status.fieldWorkPercentCompleted;
    } else {
      return 0;
    }
  }, [
    numberOfParcelsInStudy,
    classificationCalculations
  ]);

  const october31StatusAppraisalDataEntryPercentCompleted = useMemo(() => {
    if (numberOfParcelsInStudy !== 0 &&
      isNumber(classificationCalculations.october31Status.appraisalDataEntryPercentCompleted)) {
      return classificationCalculations.october31Status.appraisalDataEntryPercentCompleted;
    } else {
      return 0;
    }
  }, [
    numberOfParcelsInStudy,
    classificationCalculations
  ]);

  const augustStatusHeaderText = useMemo(() => {
    return formType !== 'FORM_4027I_JUNE' ?
      'Actual Status of Study August 31st'
      : 'Planned Interim Status of Study August 31st';
  }, [
    formType
  ]);

  const octoberStatusHeaderText = useMemo(() => {
    return formType === 'FORM_4027I_OCT' ?
      'Actual Status of Study October 31st' :
      'Planned Interim Status of Study October 31st';
  }, [
    formType
  ]);

  return <>
    <Row className="mt-4">
      <Col>
        <Table bordered responsive key="june-30-status-table">
          <thead>
            <tr>
              <th className="text-nowrap text-primary text-left w-30">Actual Status as of June 30th</th>
              <th className="text-nowrap text-primary text-center w-25">Number of Parcels in Study</th>
              <th className="text-nowrap text-primary text-center w-25">Number of Parcels Completed</th>
              <th className="text-nowrap text-primary text-center w-20">Percent Completed</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="align-middle text-nowrap">Parcel Selection</td>
              <td className="align-middle text-center">
                {formatInteger(june30NumberOfParcelsInStudy)}
              </td>
              <td className="align-middle text-center">
                {isJuneStatusEditable && <FormikNumberInput formGroupClass="mb-0"
                                                            bsSize="sm"
                                                            maxLength="9"
                                                            disableFloatingLabel={true}
                                                            ariaLabel="Parcel Selection"
                                                            aria-required={true}
                                                            onChange={() => setHasUnsavedChanges(true)}
                                                            className="text-center"
                                                            name={`classifications['${selectedTab}'].june30Status.parcelSelection`}/>}
                {!isJuneStatusEditable && formatInteger(june30Status?.parcelSelection ?? 0)}
              </td>
              <td className="align-middle text-center">
                {formatDecimal(june30StatusParcelSelectionPercentCompleted, 2, true)}
              </td>
            </tr>
            <tr>
              <td className="align-middle text-nowrap">Field Work</td>
              <td className="align-middle text-center">
                {formatInteger(june30NumberOfParcelsInStudy)}
              </td>
              <td className="align-middle text-center">
                {isJuneStatusEditable && <FormikNumberInput formGroupClass="mb-0"
                                                            bsSize="sm"
                                                            maxLength="9"
                                                            disableFloatingLabel={true}
                                                            ariaLabel="Field Work"
                                                            aria-required={true}
                                                            onChange={() => setHasUnsavedChanges(true)}
                                                            className="text-center"
                                                            name={`classifications['${selectedTab}'].june30Status.fieldWork`}/>}
                {!isJuneStatusEditable && formatInteger(june30Status?.fieldWork ?? 0)}
              </td>
              <td className="align-middle text-center">
                {formatDecimal(june30StatusFieldWorkPercentCompleted, 2, true)}
              </td>
            </tr>
            <tr>
              <td className="align-middle text-nowrap">Appraisal Data Entry</td>
              <td className="align-middle text-center">
                {formatInteger(june30NumberOfParcelsInStudy)}
              </td>
              <td className="align-middle text-center">
                {isJuneStatusEditable && <FormikNumberInput formGroupClass="mb-0"
                                                            bsSize="sm"
                                                            maxLength="9"
                                                            disableFloatingLabel={true}
                                                            ariaLabel="Appraisal Data Entry"
                                                            aria-required={true}
                                                            onChange={() => setHasUnsavedChanges(true)}
                                                            className="text-center"
                                                            name={`classifications['${selectedTab}'].june30Status.appraisalDataEntry`}/>}
                {!isJuneStatusEditable && formatInteger(june30Status?.appraisalDataEntry ?? 0)}
              </td>
              <td className="align-middle text-center">
                {formatDecimal(june30StatusAppraisalDataEntryPercentCompleted, 2, true)}
              </td>
            </tr>
          </tbody>
        </Table>
      </Col>
    </Row>
    <Row className="mt-4">
      <Col>
        <Table bordered responsive>
          <thead>
            <tr>
              <th className="text-nowrap text-primary text-left w-80">Other Categories</th>
              <th className="text-nowrap text-primary text-center">Percent Completed</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Sales Data Entry</td>
              <td className="text-center">
                {isJuneStatusEditable &&
                  <FormikPercentageInput formGroupClass="mb-0"
                                         bsSize="sm"
                                         disableFloatingLabel={true}
                                         onChange={() => setHasUnsavedChanges(true)}
                                         maxLength="7"
                                         ariaLabel="Sales Data Entry"
                                         className="text-center"
                                         name={`classifications['${selectedTab}'].june30Status.otherCategories.salesDataEntry`}/>}
                {!isJuneStatusEditable && <span>
              {formatDecimal((june30Status?.otherCategories?.salesDataEntry ?? 0) / 100, 2, true)}
            </span>}
              </td>
            </tr>
            <tr>
              <td>Land Value Study</td>
              <td className="text-center">
                {isJuneStatusEditable &&
                  <FormikPercentageInput formGroupClass="mb-0"
                                         bsSize="sm"
                                         disableFloatingLabel={true}
                                         onChange={() => setHasUnsavedChanges(true)}
                                         maxLength="7"
                                         ariaLabel="Land Value Study"
                                         className="text-center"
                                         name={`classifications['${selectedTab}'].june30Status.otherCategories.landValueStudy`}/>}
                {!isJuneStatusEditable && <span>
              {formatDecimal((june30Status?.otherCategories?.landValueStudy ?? 0) / 100, 2, true)}
            </span>}
              </td>
            </tr>
            <tr>
              <td>ECF Study</td>
              <td className="text-center">
                {isJuneStatusEditable &&
                  <FormikPercentageInput formGroupClass="mb-0"
                                         bsSize="sm"
                                         onChange={() => setHasUnsavedChanges(true)}
                                         maxLength="7"
                                         disableFloatingLabel={true}
                                         ariaLabel="ECF Study"
                                         className="text-center"
                                         name={`classifications['${selectedTab}'].june30Status.otherCategories.ecfStudy`}/>}
                {!isJuneStatusEditable && <span>
              {formatDecimal((june30Status?.otherCategories?.ecfStudy ?? 0) / 100, 2, true)}
            </span>}
              </td>
            </tr>
          </tbody>
        </Table>
      </Col>
    </Row>
    <Row className="mt-4">
      <Col>
        <Table bordered responsive key="august-status-table">
          <thead>
            <tr>
              <th className="text-nowrap text-primary text-left w-30">
                {augustStatusHeaderText}
              </th>
              <th className="text-nowrap text-primary text-center w-25">Number of Parcels in Study</th>
              <th className="text-nowrap text-primary text-center w-25">Number of Parcels Completed</th>
              <th className="text-nowrap text-primary text-center w-20">Percent Completed</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="align-middle text-nowrap">Parcel Selection</td>
              <td className="align-middle text-center">
                {formatInteger(august31NumberOfParcelsInStudy)}
              </td>
              <td className="align-middle text-center">
                {isAugustStatusEditable && <FormikNumberInput bsSize="sm"
                                                              formGroupClass="mb-0"
                                                              onChange={() => setHasUnsavedChanges(true)}
                                                              maxLength="9"
                                                              disableFloatingLabel={true}
                                                              aria-required={true}
                                                              ariaLabel="Parcel Selection"
                                                              className="text-center"
                                                              name={`classifications['${selectedTab}'].august31Status.parcelSelection`}/>}
                {!isAugustStatusEditable && formatInteger(august31Status?.parcelSelection ?? 0)}
              </td>
              <td className="align-middle text-center">
                {formatDecimal(august31StatusParcelSelectionPercentCompleted, 2, true)}
              </td>
            </tr>
            <tr>
              <td className="align-middle text-nowrap">Field Work</td>
              <td className="align-middle text-center">
                {formatInteger(august31NumberOfParcelsInStudy)}
              </td>
              <td className="align-middle text-center">
                {isAugustStatusEditable &&
                  <FormikNumberInput bsSize="sm"
                                     formGroupClass="mb-0"
                                     onChange={() => setHasUnsavedChanges(true)}
                                     maxLength="9"
                                     disableFloatingLabel={true}
                                     ariaLabel="Field Work"
                                     aria-required={true}
                                     className="text-center"
                                     name={`classifications['${selectedTab}'].august31Status.fieldWork`}/>}
                {!isAugustStatusEditable && formatInteger(august31Status?.fieldWork ?? 0)}
              </td>
              <td className="align-middle text-center">
                {formatDecimal(august31StatusFieldWorkPercentCompleted, 2, true)}
              </td>
            </tr>
            <tr>
              <td className="align-middle text-nowrap">Appraisal Data Entry</td>
              <td className="align-middle text-center">
                {formatInteger(august31NumberOfParcelsInStudy)}
              </td>
              <td className="align-middle text-center">
                {isAugustStatusEditable &&
                  <FormikNumberInput bsSize="sm"
                                     formGroupClass="mb-0"
                                     onChange={() => setHasUnsavedChanges(true)}
                                     maxLength="9"
                                     disableFloatingLabel={true}
                                     aria-required={true}
                                     ariaLabel="Appraisal Data Entry"
                                     className="text-center"
                                     name={`classifications['${selectedTab}'].august31Status.appraisalDataEntry`}/>}
                {!isAugustStatusEditable && formatInteger(august31Status?.appraisalDataEntry ?? 0)}
              </td>
              <td className="align-middle text-center">
                {formatDecimal(august31StatusAppraisalDataEntryPercentCompleted, 2, true)}
              </td>
            </tr>
          </tbody>
        </Table>
      </Col>
    </Row>
    <Row className="mt-4">
      <Col>
        <Table bordered responsive>
          <thead>
            <tr>
              <th className="text-nowrap text-primary text-left w-80">Other Categories</th>
              <th className="text-nowrap text-primary text-center">Percent Completed</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Sales Data Entry</td>
              <td className="text-center">
                {isAugustStatusEditable &&
                  <FormikPercentageInput bsSize="sm"
                                         formGroupClass="mb-0"
                                         onChange={() => setHasUnsavedChanges(true)}
                                         maxLength="7"
                                         disableFloatingLabel={true}
                                         ariaLabel="Sales Data Entry"
                                         aria-required={true}
                                         className="text-center"
                                         name={`classifications['${selectedTab}'].august31Status.otherCategories.salesDataEntry`}/>}
                {!isAugustStatusEditable && <span>
                  {formatDecimal((august31Status?.otherCategories?.salesDataEntry ?? 0) / 100, 2, true)}
                </span>}
              </td>
            </tr>
            <tr>
              <td>Land Value Study</td>
              <td className="text-center">
                {isAugustStatusEditable &&
                  <FormikPercentageInput bsSize="sm"
                                         formGroupClass="mb-0"
                                         ariaLabel="Land Value Study"
                                         disableFloatingLabel={true}
                                         onChange={() => setHasUnsavedChanges(true)}
                                         maxLength="7"
                                         className="text-center"
                                         name={`classifications['${selectedTab}'].august31Status.otherCategories.landValueStudy`}/>}
                {!isAugustStatusEditable && <span>
                  {formatDecimal((august31Status?.otherCategories?.landValueStudy ?? 0) / 100, 2, true)}
                </span>}
              </td>
            </tr>
            <tr>
              <td>ECF Study</td>
              <td className="text-center">
                {isAugustStatusEditable &&
                  <FormikPercentageInput bsSize="sm"
                                         formGroupClass="mb-0"
                                         maxLength="7"
                                         ariaLabel="ECF Study"
                                         disableFloatingLabel={true}
                                         className="text-center"
                                         name={`classifications['${selectedTab}'].august31Status.otherCategories.ecfStudy`}/>}
                {!isAugustStatusEditable && <span>
                  {formatDecimal((august31Status?.otherCategories?.ecfStudy ?? 0) / 100, 2, true)}
                </span>}
              </td>
            </tr>
          </tbody>
        </Table>
      </Col>
    </Row>
    <Row className="mt-4">
      <Col>
        <Table bordered responsive key="october-status-table">
          <thead>
            <tr>
              <th className="text-nowrap text-primary text-left w-30">
                {octoberStatusHeaderText}
              </th>
              <th className="text-nowrap text-primary text-center w-25">Number of Parcels in Study</th>
              <th className="text-nowrap text-primary text-center w-25">Number of Parcels Completed</th>
              <th className="text-nowrap text-primary text-center w-20">Percent Completed</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="align-middle text-nowrap">Parcel Selection</td>
              <td className="align-middle text-center">
                {formatInteger(numberOfParcelsInStudy)}
              </td>
              <td className="align-middle text-center">
                {showReadOnlyView && formatInteger(october31Status?.parcelSelection ?? 0)}
                {!showReadOnlyView && <FormikNumberInput formGroupClass="mb-0"
                                                         bsSize="sm"
                                                         onChange={() => setHasUnsavedChanges(true)}
                                                         maxLength="9"
                                                         disableFloatingLabel={true}
                                                         ariaLabel="Parcel Selection"
                                                         aria-required={true}
                                                         className="text-center"
                                                         name={`classifications['${selectedTab}'].october31Status.parcelSelection`}/>}
              </td>
              <td className="align-middle text-center">
                {formatDecimal(october31StatusParcelSelectionPercentCompleted, 2, true)}
              </td>
            </tr>
            <tr>
              <td className="align-middle text-nowrap">Field Work</td>
              <td className="align-middle text-center">
                {formatInteger(numberOfParcelsInStudy)}
              </td>
              <td className="align-middle text-center">
                {showReadOnlyView && formatInteger(october31Status?.fieldWork ?? 0)}
                {!showReadOnlyView && <FormikNumberInput bsSize="sm"
                                                         formGroupClass="mb-0"
                                                         onChange={() => setHasUnsavedChanges(true)}
                                                         maxLength="9"
                                                         disableFloatingLabel={true}
                                                         ariaLabel="Field Work"
                                                         aria-required={true}
                                                         className="text-center"
                                                         name={`classifications['${selectedTab}'].october31Status.fieldWork`}/>}
              </td>
              <td className="align-middle text-center">
                {formatDecimal(october31StatusFieldWorkPercentCompleted, 2, true)}
              </td>
            </tr>
            <tr>
              <td className="align-middle text-nowrap">Appraisal Data Entry</td>
              <td className="align-middle text-center">
                {formatInteger(numberOfParcelsInStudy)}
              </td>
              <td className="align-middle text-center">
                {showReadOnlyView && formatInteger(october31Status?.appraisalDataEntry ?? 0)}
                {!showReadOnlyView && <FormikNumberInput bsSize="sm"
                                                         formGroupClass="mb-0"
                                                         onChange={() => setHasUnsavedChanges(true)}
                                                         maxLength="9"
                                                         disableFloatingLabel={true}
                                                         ariaLabel="Appraisal Data Entry"
                                                         aria-required={true}
                                                         className="text-center"
                                                         name={`classifications['${selectedTab}'].october31Status.appraisalDataEntry`}/>}
              </td>
              <td className="align-middle text-center">
                {formatDecimal(october31StatusAppraisalDataEntryPercentCompleted, 2, true)}
              </td>
            </tr>
          </tbody>
        </Table>
      </Col>
    </Row>
    <Row className="mt-4">
      <Col>
        <Table bordered responsive>
          <thead>
            <tr>
              <th className="text-nowrap text-primary text-left w-80">Other Categories</th>
              <th className="text-nowrap text-primary text-center">Percent Completed</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Sales Data Entry</td>
              <td className="text-center">
                {showReadOnlyView &&
                  <span>
                    {formatDecimal((october31Status?.otherCategories?.salesDataEntry ?? 0) / 100, 2, true)}
                  </span>}
                {!showReadOnlyView && <FormikPercentageInput bsSize="sm"
                                                             formGroupClass="mb-0"
                                                             onChange={() => setHasUnsavedChanges(true)}
                                                             maxLength="7"
                                                             ariaLabel="Sales Data Entry"
                                                             disableFloatingLabel={true}
                                                             className="text-center"
                                                             name={`classifications['${selectedTab}'].october31Status.otherCategories.salesDataEntry`}/>}
              </td>
            </tr>
            <tr>
              <td>Land Value Study</td>
              <td className="text-center">
                {showReadOnlyView &&
                  <span>
                    {formatDecimal((october31Status?.otherCategories?.landValueStudy ?? 0) / 100, 2, true)}
                  </span>}
                {!showReadOnlyView && <FormikPercentageInput bsSize="sm"
                                                             formGroupClass="mb-0"
                                                             onChange={() => setHasUnsavedChanges(true)}
                                                             maxLength="7"
                                                             disableFloatingLabel={true}
                                                             ariaLabel="Land Value Study"
                                                             className="text-center"
                                                             name={`classifications['${selectedTab}'].october31Status.otherCategories.landValueStudy`}/>}
              </td>
            </tr>
            <tr>
              <td>ECF Study</td>
              <td className="text-center">
                {showReadOnlyView &&
                  <span>
                    {formatDecimal((october31Status?.otherCategories?.ecfStudy ?? 0) / 100, 2, true)}
                  </span>}
                {!showReadOnlyView && <FormikPercentageInput bsSize="sm"
                                                             onChange={() => setHasUnsavedChanges(true)}
                                                             maxLength="7"
                                                             formGroupClass="mb-0"
                                                             ariaLabel="ECF Study"
                                                             disableFloatingLabel={true}
                                                             className="text-center"
                                                             name={`classifications['${selectedTab}'].october31Status.otherCategories.ecfStudy`}/>}
              </td>
            </tr>
          </tbody>
        </Table>
      </Col>
    </Row>
  </>;
};

export default Form4027iRealPropertyTab;