import {FormikProps} from 'formik';

import {forms, LocalUnit} from '../types';
import {Form4027iDto, Form4027iLocalUnitDto} from '../types/forms';
import {Form4027iRealPropertyStatus} from '../types/forms/form4027i';
import {sumObjects} from './math';

const PERSONAL_PROPERTY = 'personalProperty';

const blankStatus = {
  numberOfParcelsInStudy: null,
  parcelSelection: null,
  auditReview: null,
  fieldWork: null,
  appraisalDataEntry: null,
  otherCategories: {
    salesDataEntry: null,
    landValueStudy: null,
    ecfStudy: null
  }
};

const blankClassification = (localUnits: LocalUnit[], year: number) => {
  return {
    localUnits: blankLocalUnits(localUnits, year),
    june30Status: blankStatus,
    august31Status: blankStatus,
    october31Status: blankStatus
  };
};

const blankPersonalPropertyStatus = {
  parcelSelection: null,
  auditReview: null
};

const blankLocalUnits = (localUnits: LocalUnit[], year: number) => {
  // Id 812019 is Manchester City, this was not available until the 2024 for 2025 equalization Cycle
  const filteredLocalUnits = localUnits.filter(localUnit => {
    return year < 2025 ? localUnit.id !== 812019 : true;
  });

  return filteredLocalUnits.map((localUnit: LocalUnit) => {
    return {
      name: localUnit.displayNameWithType,
      studyType: '',
      numberOfParcelsInStudy: '',
      numberOfParcelsInClass: '',
      localUnitId: localUnit.id
    } as forms.Form4027iLocalUnitDto;
  });
};

// This is to help older forms move to using a new localUnitId field not originally in the form data
// Eventually it will no longer be needed as no bad data will be edited or remain
const findLocalUnitIdByLocalUnitName = (localUnits: LocalUnit[], name: string) => {
  return localUnits.filter(localUnit => name === localUnit.displayNameWithType)?.[0]?.id ?? 0;
};

const mapLocalUnits = (form4027iLocalUnits: forms.Form4027iLocalUnitDto[],
                       localUnits: LocalUnit[]) => {
  // This data already contains localUnitId properly so can be returned as is.
  if (localUnits.length === form4027iLocalUnits.length && form4027iLocalUnits.filter(form4027iLocalUnit => form4027iLocalUnit.localUnitId === null ||
    form4027iLocalUnit.localUnitId === undefined ||
    form4027iLocalUnit.localUnitId === 0).length === 0) {
    return form4027iLocalUnits;
  } else {
    const mappedLocalUnits: forms.Form4027iLocalUnitDto[] = [];
    form4027iLocalUnits.forEach((form4027iLocalUnit: Form4027iLocalUnitDto) => {
      if (form4027iLocalUnit.localUnitId === null ||
        form4027iLocalUnit.localUnitId === undefined ||
        form4027iLocalUnit.localUnitId === 0) {
        mappedLocalUnits.push({
          name: form4027iLocalUnit?.name ?? '',
          studyType: form4027iLocalUnit?.studyType ?? '',
          numberOfParcelsInStudy: form4027iLocalUnit?.numberOfParcelsInStudy ?? '',
          numberOfParcelsInClass: form4027iLocalUnit?.numberOfParcelsInClass ?? '',
          localUnitId: form4027iLocalUnit?.localUnitId ?? findLocalUnitIdByLocalUnitName(localUnits, form4027iLocalUnit?.name ?? '')
        } as Form4027iLocalUnitDto);
      }
    });
    return mappedLocalUnits;
  }
};

const mapClassification = (form4027i: Form4027iDto,
                           classificationDataKey: string,
                           localUnits: LocalUnit[],
                           year: number
) => {
  form4027i.classifications = form4027i.classifications && Object.keys(form4027i.classifications).length > 0 ? form4027i.classifications :
    {
      agricultural: blankClassification(localUnits, year),
      commercial: blankClassification(localUnits, year),
      industrial: blankClassification(localUnits, year),
      residential: blankClassification(localUnits, year),
      timberCutover: blankClassification(localUnits, year),
      developmental: blankClassification(localUnits, year),
      personalProperty: {
        localUnits: blankLocalUnits(localUnits, year),
        june30Status: blankPersonalPropertyStatus,
        august31Status: blankPersonalPropertyStatus,
        october31Status: blankPersonalPropertyStatus
      }
    };

  const currentClassification = form4027i.classifications[classificationDataKey];

  if (form4027i.classifications === undefined || form4027i.classifications[classificationDataKey] === undefined) {
    form4027i.classifications[classificationDataKey] = {
      localUnits: blankLocalUnits(localUnits, year),
      june30Status: {otherCategories: {}},
      august31Status: {otherCategories: {}},
      october31Status: {otherCategories: {}}
    };
  }

  if (classificationDataKey === PERSONAL_PROPERTY) {
    return {
      localUnits: mapLocalUnits((currentClassification?.localUnits ?? []), localUnits),
      june30Status: {
        numberOfParcelsInStudy: currentClassification.june30Status.numberOfParcelsInStudy ?? '',
        parcelSelection: currentClassification.june30Status.parcelSelection ?? '',
        auditReview: currentClassification.june30Status.auditReview ?? ''
      },
      august31Status: {
        numberOfParcelsInStudy: currentClassification.august31Status.numberOfParcelsInStudy ?? '',
        parcelSelection: currentClassification.august31Status.parcelSelection ?? '',
        auditReview: currentClassification.august31Status.auditReview ?? ''
      },
      october31Status: {
        parcelSelection: currentClassification.october31Status.parcelSelection ?? '',
        auditReview: currentClassification.october31Status.auditReview ?? ''
      }
    };
  } else {
    return {
      localUnits: mapLocalUnits((currentClassification?.localUnits ?? []), localUnits),
      june30Status: {
        numberOfParcelsInStudy: currentClassification.june30Status.numberOfParcelsInStudy ?? '',
        parcelSelection: currentClassification.june30Status.parcelSelection ?? '',
        fieldWork: currentClassification.june30Status.fieldWork ?? '',
        appraisalDataEntry: currentClassification.june30Status.appraisalDataEntry ?? '',
        otherCategories: {
          salesDataEntry: currentClassification.june30Status.otherCategories.salesDataEntry ?? '',
          landValueStudy: currentClassification.june30Status.otherCategories.landValueStudy ?? '',
          ecfStudy: currentClassification.june30Status.otherCategories.ecfStudy ?? ''
        }
      },
      august31Status: {
        numberOfParcelsInStudy: currentClassification.august31Status.numberOfParcelsInStudy ?? '',
        parcelSelection: currentClassification.august31Status.parcelSelection ?? '',
        fieldWork: currentClassification.august31Status.fieldWork ?? '',
        appraisalDataEntry: currentClassification.august31Status.appraisalDataEntry ?? '',
        otherCategories: {
          salesDataEntry: currentClassification.august31Status.otherCategories.salesDataEntry ?? '',
          landValueStudy: currentClassification.august31Status.otherCategories.landValueStudy ?? '',
          ecfStudy: currentClassification.august31Status.otherCategories.ecfStudy ?? ''
        }
      },
      october31Status: {
        parcelSelection: currentClassification.october31Status.parcelSelection ?? '',
        fieldWork: currentClassification.october31Status.fieldWork ?? '',
        appraisalDataEntry: currentClassification.october31Status.appraisalDataEntry ?? '',
        otherCategories: {
          salesDataEntry: currentClassification.october31Status.otherCategories.salesDataEntry ?? '',
          landValueStudy: currentClassification.october31Status.otherCategories.landValueStudy ?? '',
          ecfStudy: currentClassification.october31Status.otherCategories.ecfStudy ?? ''
        }
      }
    };
  }
};

const getCurrentClassificationNumber = (selectedTab: string) => {
  switch (selectedTab) {
    case 'agricultural':
      return '100';
    case 'commercial':
      return '200';
    case 'industrial':
      return '300';
    case 'residential':
      return '400';
    case 'timberCutover':
      return '500';
    case 'developmental':
      return '600';
    case 'personalProperty':
      return 'personalProperty';
    default:
      return '';
  }
};

const getStatusCalculations = (formType: string,
                               statusKey: string,
                               totals: {
                                 numberOfParcelsInStudy: number,
                                 numberOfParcelsInClass: number,
                                 percentStudied: number
                               },
                               currentClassificationValues: {
                                 [statusKey: string]: Form4027iRealPropertyStatus
                               },
                               numberOfParcelsInStudy: number) => {
  const currentStatusValues = currentClassificationValues[statusKey];
  if (currentStatusValues === null) {
    return {
      parcelSelectionPercentCompleted: 0,
      fieldWorkPercentCompleted: 0,
      appraisalDataEntryPercentCompleted: 0
    };
  }

  if (statusKey === 'june30Status') {
    const shouldUseCalculatedValue = formType === 'FORM_4027I_JUNE' || formType === 'FORM_4027I';
    const june30NumberOfParcelsInStudy = shouldUseCalculatedValue ?
      totals.numberOfParcelsInStudy : numberOfParcelsInStudy;

    return {
      parcelSelectionPercentCompleted: june30NumberOfParcelsInStudy !== 0 ?
        (currentStatusValues?.parcelSelection ?? 0) / june30NumberOfParcelsInStudy : 0,
      fieldWorkPercentCompleted: june30NumberOfParcelsInStudy !== 0 ?
        (currentStatusValues?.fieldWork ?? 0) / june30NumberOfParcelsInStudy : 0,
      appraisalDataEntryPercentCompleted: june30NumberOfParcelsInStudy !== 0 ?
        (currentStatusValues?.appraisalDataEntry ?? 0) / june30NumberOfParcelsInStudy : 0
    };
  } else if (statusKey === 'august31Status') {
    const shouldUseCalculatedValue = formType === 'FORM_4027I_AUG' || formType === 'FORM_4027I';
    const august31NumberOfParcelsInStudy = shouldUseCalculatedValue ?
      totals.numberOfParcelsInStudy : numberOfParcelsInStudy;

    return {
      parcelSelectionPercentCompleted: august31NumberOfParcelsInStudy !== 0 ?
        (currentStatusValues?.parcelSelection ?? 0) / august31NumberOfParcelsInStudy : 0,
      fieldWorkPercentCompleted: august31NumberOfParcelsInStudy !== 0 ?
        (currentStatusValues?.fieldWork ?? 0) / august31NumberOfParcelsInStudy : 0,
      appraisalDataEntryPercentCompleted: august31NumberOfParcelsInStudy !== 0 ?
        (currentStatusValues?.appraisalDataEntry ?? 0) / august31NumberOfParcelsInStudy : 0
    };
  } else {
    return {
      parcelSelectionPercentCompleted: numberOfParcelsInStudy !== 0 ?
        (currentStatusValues?.parcelSelection ?? 0) / totals.numberOfParcelsInStudy : 0,
      fieldWorkPercentCompleted: numberOfParcelsInStudy !== 0 ?
        (currentStatusValues?.fieldWork ?? 0) / totals.numberOfParcelsInStudy : 0,
      appraisalDataEntryPercentCompleted: numberOfParcelsInStudy !== 0 ?
        (currentStatusValues?.appraisalDataEntry ?? 0) / totals.numberOfParcelsInStudy : 0
    };
  }
};

const getClassificationCalculations = (formType: string,
                                       formikProps: FormikProps<any>,
                                       classificationKey: string) => {
  const currentClassificationValues = formikProps.values.classifications[classificationKey.toString()];
  let totals = {
    numberOfParcelsInStudy: sumObjects(currentClassificationValues.localUnits, 'numberOfParcelsInStudy'),
    numberOfParcelsInClass: sumObjects(currentClassificationValues.localUnits, 'numberOfParcelsInClass'),
    percentStudied: 0
  };

  totals.percentStudied = totals.numberOfParcelsInClass !== 0 ?
    totals.numberOfParcelsInStudy / totals.numberOfParcelsInClass : 0;

  const june30StatusNumberOfParcelsInStudy = formType === 'FORM_4027I_JUNE' || formType === 'FORM_4027I' ?
    currentClassificationValues.numberOfParcelsInStudy : currentClassificationValues.june30Status.numberOfParcelsInStudy;

  const august31StatusNumberOfParcelsInStudy = formType === 'FORM_4027I_AUG' || formType === 'FORM_4027I' ?
    currentClassificationValues.numberOfParcelsInStudy : currentClassificationValues.august31Status.numberOfParcelsInStudy;

  return {
    totals,
    june30Status: getStatusCalculations(
      formType,
      'june30Status',
      totals,
      currentClassificationValues,
      june30StatusNumberOfParcelsInStudy
    ),
    august31Status: getStatusCalculations(
      formType,
      'august31Status',
      totals,
      currentClassificationValues,
      august31StatusNumberOfParcelsInStudy
    ),
    october31Status: getStatusCalculations(
      formType,
      'october31Status',
      totals,
      currentClassificationValues,
      currentClassificationValues.numberOfParcelsInStudy
    )
  };
};

const getNumberOfParcelsInStudy = (formikProps: FormikProps<any>,
                                   selectedClassificationFilter: string) => {
  return sumObjects(
    formikProps.values.classifications[selectedClassificationFilter.toString()].localUnits,
    'numberOfParcelsInStudy'
  );
};

const getInitialValues = (form4027iDto: Form4027iDto,
                          filteredLocalUnits: LocalUnit[],
                          year: number) => {
  return {
    classifications: {
      agricultural: mapClassification(
        form4027iDto,
        'agricultural',
        filteredLocalUnits,
        year
      ),
      commercial: mapClassification(
        form4027iDto,
        'commercial',
        filteredLocalUnits,
        year
      ),
      industrial: mapClassification(
        form4027iDto,
        'industrial',
        filteredLocalUnits,
        year),
      residential: mapClassification(
        form4027iDto,
        'residential',
        filteredLocalUnits,
        year
      ),
      timberCutover: mapClassification(
        form4027iDto,
        'timberCutover',
        filteredLocalUnits,
        year
      ),
      developmental: mapClassification(
        form4027iDto,
        'developmental',
        filteredLocalUnits,
        year
      ),
      personalProperty: mapClassification(
        form4027iDto,
        'personalProperty',
        filteredLocalUnits,
        year
      )
    },
    additionalInformation: {
      boundaryChanges: form4027iDto?.additionalInformation?.boundaryChanges?.toString?.() ?? '',
      boundaryChangesComments: form4027iDto?.additionalInformation?.boundaryChangesComments ?? '',
      adequateProcedures: form4027iDto?.additionalInformation?.adequateProcedures?.toString?.() ?? '',
      adequateProceduresComments: form4027iDto?.additionalInformation?.adequateProceduresComments ?? '',
      overallStudyComments: form4027iDto?.additionalInformation?.overallStudyComments ?? '',
      comments: {
        '100': {
          landValueStudyComments: form4027iDto?.additionalInformation?.comments?.['100']?.landValueStudyComments ?? '',
          ecfStudyComments: form4027iDto?.additionalInformation?.comments?.['100']?.ecfStudyComments ?? ''
        },
        '200': {
          landValueStudyComments: form4027iDto?.additionalInformation?.comments?.['200']?.landValueStudyComments ?? '',
          ecfStudyComments: form4027iDto?.additionalInformation?.comments?.['200']?.ecfStudyComments ?? ''
        },
        '300': {
          landValueStudyComments: form4027iDto?.additionalInformation?.comments?.['300']?.landValueStudyComments ?? '',
          ecfStudyComments: form4027iDto?.additionalInformation?.comments?.['300']?.ecfStudyComments ?? ''
        },
        '400': {
          landValueStudyComments: form4027iDto?.additionalInformation?.comments?.['400']?.landValueStudyComments ?? '',
          ecfStudyComments: form4027iDto?.additionalInformation?.comments?.['400']?.ecfStudyComments ?? ''
        },
        '500': {
          landValueStudyComments: form4027iDto?.additionalInformation?.comments?.['500']?.landValueStudyComments ?? '',
          ecfStudyComments: form4027iDto?.additionalInformation?.comments?.['500']?.ecfStudyComments ?? ''
        },
        '600': {
          landValueStudyComments: form4027iDto?.additionalInformation?.comments?.['600']?.landValueStudyComments ?? '',
          ecfStudyComments: form4027iDto?.additionalInformation?.comments?.['600']?.ecfStudyComments ?? ''
        }
      }
    },
    cedCertification: {
      yearsInPosition: form4027iDto?.cedCertification?.yearsInPosition ?? '',
      yearsInField: form4027iDto?.cedCertification?.yearsInField ?? '',
      certificationLevelRequired: form4027iDto?.cedCertification?.certificationLevelRequired ?? '',
      certificationLevelHeld: form4027iDto?.cedCertification?.certificationLevelHeld ?? '',
      numberOfStaffMcao2: form4027iDto?.cedCertification?.numberOfStaffMcao2 ?? '',
      numberOfStaffMaao3: form4027iDto?.cedCertification?.numberOfStaffMaao3 ?? '',
      numberOfStaffMmao4: form4027iDto?.cedCertification?.numberOfStaffMmao4 ?? '',
      numberOfStaffClerical: form4027iDto?.cedCertification?.numberOfStaffClerical ?? '',
      numberOfStaffOther: form4027iDto?.cedCertification?.numberOfStaffOther ?? '',
      camaGisSoftware: form4027iDto?.cedCertification?.camaGisSoftware ?? '',
      camaGisSqlVersion: form4027iDto?.cedCertification?.camaGisSqlVersion ?? '',
      camaGis: form4027iDto?.cedCertification?.camaGis ?? '',
      certificationRequirementsComments: form4027iDto?.cedCertification?.certificationRequirementsComments ?? '',
      contactOfficeHours: form4027iDto?.cedCertification?.contactOfficeHours ?? '',
      contactAddress: form4027iDto?.cedCertification?.contactAddress ?? '',
      contactPhoneNumber: form4027iDto?.cedCertification?.contactPhoneNumber ?? '',
      contactEmail: form4027iDto?.cedCertification?.contactEmail ?? '',
      contactComments: form4027iDto?.cedCertification?.contactComments ?? ''
    }
  };
};

export {
  blankClassification,
  getCurrentClassificationNumber,
  getClassificationCalculations,
  getInitialValues,
  getNumberOfParcelsInStudy
};