import {MiSuiteJurisdiction, MiSuiteRole, ssoUtils, User} from '@reasoncorp/kyber-js';

const isFosAnalystForCounty = (currentUser: User | undefined, countyId: number) => {
  return ssoUtils.hasJurisdictionCanonicalIdAndRole(currentUser, countyId, MiSuiteRole.FOS_ANALYST);
};

const isFosSupervisorForCounty = (currentUser: User | undefined, countyId: number) => {
  return ssoUtils.hasJurisdictionCanonicalIdAndRole(currentUser, countyId, MiSuiteRole.FOS_SUPERVISOR);
};

const hasLockAccessToCounty = (currentUser: User | undefined, countyId: number) => {
  return ssoUtils.hasMiSuiteAdminRole(currentUser, MiSuiteJurisdiction.MEG_APP) ||
    isFosSupervisorForCounty(currentUser, countyId) ||
    isFosAnalystForCounty(currentUser, countyId);
};

export {
  hasLockAccessToCounty,
  isFosAnalystForCounty,
  isFosSupervisorForCounty
};